require('./catalog.scss');

import * as dompack from 'dompack';
import RPCFormBase from '@mod-publisher/js/forms/rpc';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import * as whintegration from '@mod-system/js/wh/integration';
import * as forms from '@mod-publisher/js/forms';

import $ from 'jquery';
window.jQuery = $;
require('./garlic.min.js'); // invoice form local storage saving

let storageTag = 'hvb';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-catalog-product'))
    return;

  $('.swiper-slide').click(function(){
    let bg = $(this).css('background-image');
    bg = bg.replace('url(','').replace(')','').replace(/\"/gi, "");

    $.magnificPopup.open({
      items: {
        src: bg
      },
      type: 'image'
    });
  });
});

class InvoiceForm extends RPCFormBase {
  constructor(node) {
    super(node);
  }

  getFormExtraSubmitData() {
    return { ...whintegration.config.obj, // productid, myquotationslink
           };
  }

  onSubmitSuccess(result) {
    $('#catalog-my-orders-link').attr('href', result.myquotationslink);
  }
}

forms.registerHandler("herders_van_balloo:formhandler", form => new InvoiceForm(form));
